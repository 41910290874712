<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12" v-if="false">
            <b-form-group
              label="Cover"
              invalid-feedback="Cover are required"
              required
              ref="docs"
            >
              <div>
                <b-spinner
                  v-if="docLoading"
                  type="grow"
                  label="Loading..."
                  variant="primary"
                  class="m-5"
                />
                <b-img
                  ref="previewEl"
                  src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                  @click="$refs.mediaDoc.click()"
                  v-else-if="!mediaLoaded"
                  style="border-radius: 10px"
                  height="120px"
                  width="120px"
                />
                <div v-else class="d-flex align-items-center">
                  <b-img
                    v-if="isImage(myObj.media)"
                    ref="previewEl"
                    :src="myObj.media"
                    @click="$refs.mediaDoc.click()"
                    style="border-radius: 10px"
                    height="120px"
                    width="120px"
                  />
                  <a :href="myObj.media" target="_blank" v-else>
                    <div class="file-type">
                      {{ myObj.media.split(".").pop().toUpperCase() }}
                    </div>
                  </a>
                  <feather-icon
                    icon="Trash2Icon"
                    size="22"
                    class="text-danger cursor-pointer ml-1"
                    @click="removeMedia()"
                  />
                </div>
                <input
                  type="file"
                  id="mediaDoc"
                  hidden
                  ref="mediaDoc"
                  @change="addMedia()"
                  accept="image/*,.pdf"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Message"
              invalid-feedback="Message is required."
              ref="button"
            >
              <b-form-input
                placeholder="Enter text."
                ref="button"
                v-model="myObj.text"
                @focusout="CheckButton()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Response"
              invalid-feedback="Response is required."
              ref="msg"
            >
              <b-form-textarea
                ref="msg"
                placeholder="Enter response message."
                v-model="myObj.message"
                @focusout="CheckResText()"
                rows="5"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Status"
              invalid-feedback="Status is required."
              ref="status"
            >
              <v-select
                v-model="myObj.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(opt) => opt.value"
                label="text"
                :clearable="false"
                ref="status"
                placeholder="Select status"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Include"
              invalid-feedback="Include is required."
              ref="sendto"
            >
              <v-select
                v-model="myObj.sendTo"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sendOptions"
                :reduce="(opt) => opt.value"
                label="text"
                :clearable="false"
                ref="sendto"
                placeholder="Select"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="12">
            <b-form-group
              label=""
              invalid-feedback="Documents are required"
              required
              ref="docs"
            >
              <b-button
                ref="docs"
                @click="$refs.mediaDoc.click()"
                variant="primary"
                :disabled="request || docLoading"
                block
              >
                <b-spinner
                  v-if="docLoading"
                  type="grow"
                  small
                  label="Loading..."
                />
                <span v-else>Upload</span>
              </b-button>
              <input
                type="file"
                id="mediaDoc"
                hidden
                ref="mediaDoc"
                @change="addMedia()"
                accept="image/*,.pdf"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            v-if="mediaLoaded"
            style="display: flex; align-items: center"
          >
            <div class="file-type">
              {{ myObj.media.split(".").pop().toUpperCase() }}
            </div>
            <feather-icon
              icon="Trash2Icon"
              size="22"
              class="text-danger cursor-pointer"
              @click="removeMedia()"
            />
          </b-col> -->

          <b-col md="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Greeting Message</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12">
            <b-form-group
              label="Cover"
              invalid-feedback="Cover are required"
              required
              ref="docs"
            >
              <div>
                <b-spinner
                  v-if="docLoading"
                  type="grow"
                  label="Loading..."
                  variant="primary"
                  class="m-5"
                />
                <b-img
                  ref="previewEl"
                  src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                  @click="$refs.grMedia.click()"
                  v-else-if="!Grmedia"
                  style="border-radius: 10px"
                  height="120px"
                  width="120px"
                />
                <div v-else class="d-flex align-items-center">
                  <b-img
                    v-if="isImage(greet.media)"
                    ref="previewEl"
                    :src="greet.media"
                    @click="$refs.grMedia.click()"
                    style="border-radius: 10px"
                    height="120px"
                    width="120px"
                  />
                  <a :href="greet.media" target="_blank" v-else>
                    <div class="file-type">
                      {{ greet.media.split(".").pop().toUpperCase() }}
                    </div>
                  </a>
                  <feather-icon
                    icon="Trash2Icon"
                    size="22"
                    class="text-danger cursor-pointer ml-1"
                    @click="removeGrMedia()"
                  />
                </div>
                <input
                  type="file"
                  id="grMedia"
                  hidden
                  ref="grMedia"
                  @change="addGrMedia()"
                  accept="image/*,.pdf"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1 mt-1"
            md="12"
          >
            <h4 class="mr-1">Inactive / Active</h4>
            <b-form-checkbox v-model="greetStatus" switch />
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Response Message"
              invalid-feedback="Response Message is required."
              ref="greetmsg"
            >
              <b-form-textarea
                ref="greetmsg"
                placeholder="Enter greeting message."
                v-model="greet.message"
                @focusout="CheckGMessage()"
                rows="5"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <!-- <b-col md="12">
            <b-form-group
              label=""
              invalid-feedback="Documents are required"
              required
              ref="docs"
            >
              <b-button
                ref="docs"
                @click="$refs.grMedia.click()"
                variant="primary"
                :disabled="request || docLoading"
                block
              >
                <b-spinner
                  v-if="docLoading"
                  type="grow"
                  small
                  label="Loading..."
                />
                <span v-else>Upload</span>
              </b-button>
              <input
                type="file"
                id="grMedia"
                hidden
                ref="grMedia"
                @change="addGrMedia()"
                accept="image/*,.pdf"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            v-if="Grmedia"
            style="display: flex; align-items: center"
          >
            <div class="file-type">
              {{ greet.media.split(".").pop().toUpperCase() }}
            </div>
            <feather-icon
              icon="Trash2Icon"
              size="22"
              class="text-danger cursor-pointer"
              @click="removeGrMedia()"
            />
          </b-col> -->

          <b-col md="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="AddGreet()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="12" cols="12">
          <!-- @click="visibility2 = true" -->
          <b-button
            @click="AddOpen()"
            block
            class="mb-50"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Quick Reply</span>
          </b-button>
        </b-col>
        <!-- <b-col xl="2" lg="3" md="4" sm="12" cols="12">
          <b-button
            @click="greetOpen()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
          >
            <span class="align-middle">Greeting</span>
          </b-button>
        </b-col> -->

        <!--  
          xl="8"
          lg="6"
          md="4"
          sm="12" -->
        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          xl="10"
          lg="9"
          md="8"
          sm="12"
          cols="12"
        >
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        show-empty
        responsive
        hover
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #head(actions)="data">
          <div class="text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Edit"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Edit(data.item)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.right
              title="Delete"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Delete(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BAvatar,
    BImg,
    BFormTextarea,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    this.LoadData();
    // this.LoadGreeting();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.text.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.message.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      titleText: "This is title",
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility2: false,

      sidebarTitle: "Add Template",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "text", key: "text" },
        { label: "message", key: "message" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      rangeDate: null,
      myObj: {
        id: 0,
        text: "",
        message: "",
        media: "",
        status: "",
        campusID: this.$store.state.userData.cId,
        sendTo: "",
      },

      sendOptions: [
        { text: "Attendance", value: "attendance" },
        { text: "Homework", value: "homework" },
        { text: "Challan", value: "challan" },
        { text: "Result", value: "result" },
        { text: "Salary Slip", value: "salary-slip" },
        // { text: "Last payment slip", value: "Last payment slip" },
      ],
      statusOptions: [
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ],
      greet: {},
      timeout: 10000,
      mediaLoaded: false,
      Grmedia: false,
      greetStatus: false,
      attachments: [],
      docLoading: false,
    };
  },
  validations: {
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },

    async Edit(item) {
      //   var obj = {
      //     url:
      //       this.$store.state.domain +
      //       "BotQuickReply/GetSelected?id=" +
      //       id +
      //       "&db=" +
      //       this.$store.state.userData.db,
      //     token: this.$store.state.userData.token,
      //   };
      //   this.myObj = await this.get(obj);
      this.myObj = { ...item };
      // console.log("editObj:", this.myObj);
      if (this.myObj.media == null || this.myObj.media == "")
        this.mediaLoaded = false;
      else this.mediaLoaded = true;

      this.visibility = true;
      var elem = this.$refs["button"];
      elem.state = undefined;
      var elem = this.$refs["msg"];
      elem.state = undefined;
      var elem = this.$refs["status"];
      elem.state = undefined;

      this.sidebarTitle = "Edit Quick Reply";
      this.sidebarButton = "Update";
    },
    async greetOpen() {
      if (this.greet.media == null || this.greet.media == "")
        this.Grmedia = false;
      else this.Grmedia = true;

      this.visibility2 = true;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        text: "",
        message: "",
        media: "",
        status: "active",
        campusID: this.$store.state.userData.cId,
        sendTo: "",
      };
      this.mediaLoaded = false;
      var elem = this.$refs["button"];
      elem.state = undefined;
      var elem = this.$refs["msg"];
      elem.state = undefined;
      var elem = this.$refs["status"];
      elem.state = undefined;

      this.sidebarTitle = "Add Quick Reply";
      this.sidebarButton = "Save";
      this.visibility = true;
    },

    CheckButton() {
      var elem = this.$refs["button"];
      if (this.myObj.text.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckResText() {
      var elem = this.$refs["msg"];
      if (this.myObj.message.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGMessage() {
      var elem = this.$refs["greetmsg"];
      if (this.greet.message.trim() == "" || this.greet.message == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckStatus() {
      var elem = this.$refs["status"];
      if (this.myObj.status == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    removeMedia() {
      this.myObj.media = null;
      this.mediaLoaded = false;
      this.$refs.mediaDoc.files = null;
    },
    addMedia() {
      let media = this.$refs.mediaDoc.files[0];
      if (media != "") {
        this.mediaLoaded = false;
        this.docLoading = true;
        let formData = new FormData();
        formData.append("file", media);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp[0].path;
            this.myObj.media = resp;
            this.docLoading = false;
            this.mediaLoaded = true;
            // console.log(this.myObj.media);
          });
      }
    },
    removeGrMedia() {
      this.greet.media = null;
      this.Grmedia = false;
      this.$refs.grMedia.files = null;
    },
    addGrMedia() {
      let media = this.$refs.grMedia.files[0];
      if (media != "") {
        this.Grmedia = false;
        this.docLoading = true;
        let formData = new FormData();
        formData.append("file", media);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp[0].path;
            this.greet.media = resp;
            this.docLoading = false;
            this.Grmedia = true;
            // console.log(this.greet);
          });
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadGreeting() {
      //   this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "BotGreeting/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.greet = await this.get(obj);
      if (this.greet.status == null || this.greet.status == "") {
        this.greet.status = "inactive";
        this.greetStatus = false;
      } else if (this.greet.status == "active") {
        this.greetStatus = true;
      }
      //   this.dataLoading = false;
      // console.log("greet", this.greet);
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "BotQuickReply?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      if (this.items.length != 0) {
        this.totalRows = this.items.length;
      }
      this.dataLoading = false;
      // console.log("rep", this.items);
    },

    async AddGreet() {
      if (this.CheckGMessage() == true) {
        this.request = true;
        if (this.greetStatus) {
          this.greet.status = "active";
        } else {
          this.greet.status = "inactive";
        }
        // console.log("obj:", this.myObj);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "BotGreeting/Save?db=" +
            this.$store.state.userData.db,
          body: this.greet,
          message: "Bot Greeting added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.LoadGreeting();
          this.visibility2 = false;
        }
      }
    },

    async Add() {
      this.CheckButton();
      this.CheckResText();
      this.CheckStatus();
      if (
        this.CheckButton() == false ||
        this.CheckResText() == false ||
        this.CheckStatus() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          // console.log("obj:", this.myObj);
          var status = await this.post({
            url:
              this.$store.state.domain +
              "BotQuickReply?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Quick reply added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.visibility = false;
            this.LoadData();
          }
        } else {
          //Edit
          this.request = true;
          // console.log("edit_obj:", this.myObj);
          var status = await this.put({
            url:
              this.$store.state.domain +
              "BotQuickReply/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Quick reply updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.visibility = false;
            this.LoadData();
          }
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "BotQuickReply/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Quick reply removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(status);
        if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.file-type {
  background-color: #e62e2d;
  font-family: "Helvetica", sans-serif;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  height: 80px;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
</style>
